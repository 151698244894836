<template>
  <v-dialog
    v-model="dialogCliente"
    @click:outside="$emit('update:dialogCliente', false)"
    @keydown.esc="$emit('update:dialogCliente', false)"
    max-width="1300px"
    scrollable
  >
    <v-card flat :loading="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{
          edit
            ? $tc("global.editar") + " " + $tc("global.cliente")
            : $tc("global.add") + " " + $tc("global.cliente")
        }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogCliente', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-tabs v-model="tab">
          <v-tab class="caption font-weight-bold justify-start">
            {{ $tc("global.informacoes") }}
          </v-tab>

          <v-tab v-if="edit" class="caption font-weight-bold justify-start">
            {{ $tc("global.endereco", 2) }}
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-form
              @submit.prevent
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <v-row class="pa-4">
                <!-- Nome -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.nome"
                    :label="$tc('global.nome')"
                    outlined
                    dense
                    :rules="formRules"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Documento -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.doc_ci"
                    :label="$tc('global.documento') + ` CI`"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Documento -->
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="cliente.doc_ruc"
                    :label="$tc('global.documento') + ` RUC`"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <!-- Data Nascimento -->
                <v-col cols="12" md="3">
                  <DataField
                    :data_sync.sync="cliente.data_nascimento"
                    :label="$tc('global.data-nascimento')"
                  />
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="cliente.email"
                    :label="$tc('global.email')"
                    dense
                    outlined
                    hide-details
                    validate-on-blur
                    clearable
                    :rules="cliente.email ? emailRules : undefined"
                  ></v-text-field>
                </v-col>

                <!-- Gênero -->
                <v-col cols="12" md="2">
                  <v-select
                    v-model="cliente.genero"
                    :label="$tc('global.genero')"
                    :items="tipos_genero"
                    dense
                    hide-details
                    outlined
                  ></v-select>
                </v-col>

                <!-- Estado civil -->
                <v-col cols="12" md="2">
                  <v-select
                    return-object
                    v-model="selectedEstadoCivil"
                    :label="$tc('global.estado_civil')"
                    :items="estados_civis"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>

                <!-- celular -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="cliente.celular"
                    :label="$tc('global.celular')"
                    dense
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <!-- telefone -->
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="cliente.telefone"
                    :label="$tc('global.telefone')"
                    dense
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>

                <!-- Lista Preço -->
                <v-col cols="12" md="3">
                  <v-select
                    v-model.number="cliente.lista_preco_id"
                    :items="listas"
                    clearable
                    item-text="descricao"
                    item-value="id"
                    dense
                    hide-details
                    outlined
                    required
                    :rules="formRules"
                    :label="$tc('global.listadepreco')"
                    :loading="loadingListas"
                  ></v-select>
                </v-col>

                <!-- Moeda -->
                <v-col cols="12" md="3">
                  <v-select
                    v-model="selectedMoeda"
                    :items="moedas"
                    clearable
                    item-text="sigla"
                    item-value="id_moeda"
                    dense
                    outlined
                    hide-details
                    required
                    return-object
                    :rules="formRules"
                    :label="$tc('global.moeda')"
                  >
                  </v-select>
                </v-col>

                <!-- Data Limite Crédito -->
                <v-col cols="12" md="3">
                  <DataField
                    :data_sync.sync="cliente.data_limite_credito"
                    :obrigatorio="creditoLimiteNotZero"
                    :label="$tc('global.data-limite-credito')"
                  />
                </v-col>

                <!-- Credito Cliente -->
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="valorCreditoCliente"
                    v-model="valorCreditoCliente"
                    label="Credito"
                    dense
                    required
                    :rules="formRules"
                    :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                    outlined
                    hide-details
                    height="40px"
                    :disabled="!selectedMoeda"
                    style="display: flex"
                    class="remove-underline input-font-size input-right"
                    v-currency="vCurrencyOptions"
                  >
                  </v-text-field>
                </v-col>

                <!-- agente retentor -->
                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    readonly
                    hide-details
                    :value="$tc('global.agenteRetentor')"
                  >
                    <template v-slot:append>
                      <v-switch
                        v-model="cliente.agente_retentor"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    dense
                    outlined
                    readonly
                    hide-details
                    :value="$tc('global.pessoaJuridica')"
                  >
                    <template v-slot:append>
                      <v-switch
                        v-model="cliente.pessoa_juridica"
                        :false-value="false"
                        :true-value="true"
                        class="mt-0 pt-0"
                        hide-details
                      >
                      </v-switch>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col v-if="!edit" cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>

                <v-row v-if="!edit" class="px-3 pt-3">
                  <!-- Rua -->
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="endereco.rua"
                      label="Rua"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- Numero -->
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="endereco.numero"
                      label="Número"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- Cidades -->
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="endereco.cidade_nome"
                      label="Cidade"
                      dense
                      outlined
                      hide-details
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Estado -->
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="endereco.estado"
                      label="Estado"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- pais -->
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="endereco.pais"
                      label="País"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <!-- Bairro -->
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="endereco.bairro"
                      label="Bairro"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions class="pa-3">
              <v-spacer></v-spacer>
              <v-btn
                color="button_2"
                class="white--text"
                depressed
                small
                :disabled="!validForm"
                :loading="loading"
                @click="edit ? updateCliente() : createCliente()"
              >
                {{ edit ? $tc("global.salvar") : $tc("global.criar") }}
                <v-icon right>{{ edit ? "mdi-plus" : "mdi-check" }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-tab-item>

          <v-tab-item class="pb-3">
            <DadosEndereco
              v-if="tab === 1"
              :cliente_id="this.cliente_id"
              :key="tab"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { inputRequired, validaEmail } from "@/helpers/utils.js";
import { fetchListasPrecos } from "@/api/listas-precos/listasprecos.js";
import { mapState } from "vuex";
import {
  getClienteCombobox,
  postClienteCombobox,
  putClienteCombobox,
} from "@/api/clientes/clientes.js";
export default {
  name: "DialogCliente",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    DadosEndereco: () =>
      import("@/views/dashboard/clientes/components/DadosEndereco.vue"),
  },

  props: {
    dialogCliente: {
      type: Boolean,
      required: true,
    },
    cliente_id: {
      type: [String, Number],
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
      emailRules: [validaEmail],
      cliente: {
        agente_retentor: false,
        pessoa_juridica: false,
      },
      endereco: {},
      tipos_genero: ["MASCULINO", "FEMININO", "NÃO DECLARAR"],
      selectedCidade: null,
      listas: [],
      loadingListas: false,
      selectedMoeda: null,
      valorCreditoCliente: 0,
      cliente_original: null,
      selectedEstadoCivil: null,
      tab: 0,
      estados_civis: [
        this.$tc("global.solteiro").toUpperCase(),
        this.$tc("global.casado").toUpperCase(),
        this.$tc("global.divorciado").toUpperCase(),
        this.$tc("global.viuvo").toUpperCase(),
      ],
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    creditoLimiteNotZero: function () {
      return this.valorCreditoCliente == 0 || this.valorCreditoCliente == "0,00"
        ? false
        : true;
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {
    selectedMoeda() {
      if (this.selectedMoeda) {
        this.cliente.moeda_id = this.selectedMoeda.id_moeda;
      }
    },
    selectedEstadoCivil() {
      this.cliente.estado_civil = this.selectedEstadoCivil;
    },
  },

  methods: {
    getListas() {
      this.loadingListas = true;
      return fetchListasPrecos()
        .then((response) => {
          this.listas = response;
          this.loadingListas = false;
        })
        .catch(() => {
          this.loadingListas = false;
        });
    },

    async getClienteCombobox() {
      await getClienteCombobox(this.cliente_id).then((response) => {
        this.cliente = response;
        this.cliente_original = { ...response };
        this.selectedEstadoCivil = this.cliente.estado_civil;
        this.selectedMoeda = this.moedas.find((moeda) => {
          return moeda.id_moeda === this.cliente.moeda_id;
        });
        this.$ci.setValue(this.$refs.valorCreditoCliente, this.cliente.credito);
      });
    },

    createCliente() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (!this.cliente.doc_ci && !this.cliente.doc_ruc) {
          this.$toast.error("por favor inserte algún documento");
          this.loading = false;
          return;
        }

        const cliente = new FormData();

        let cliente_2 = {};
        cliente_2.doc_ci = this.cliente.doc_ci;
        cliente_2.doc_ruc = this.cliente.doc_ruc;
        cliente_2.nome = this.cliente.nome;
        cliente_2.email = this.cliente.email;
        cliente_2.endereco = this.cliente.endereco;
        cliente_2.celular = this.cliente.celular;
        cliente_2.telefone = this.cliente.telefone;
        cliente_2.localizacao = this.cliente.localizacao;
        cliente_2.lista_preco_id = this.cliente.lista_preco_id;
        cliente_2.moeda_id = this.cliente.moeda_id;
        cliente_2.data_nascimento = this.cliente.data_nascimento;
        cliente_2.genero = this.cliente.genero;
        cliente_2.agente_retentor = this.cliente.agente_retentor;
        cliente_2.local_trabalho = this.cliente.local_trabalho;
        cliente_2.pessoa_juridica = this.cliente.pessoa_juridica;
        cliente_2.data_limite_credito = this.cliente.data_limite_credito;
        cliente_2.estado_civil = this.cliente.estado_civil;
        cliente_2.credito = this.$ci.parse(
          this.valorCreditoCliente,
          this.vCurrencyOptions
        );

        for (let key in cliente_2) {
          if (
            cliente_2[key] !== null &&
            cliente_2[key] !== undefined &&
            cliente_2[key] !== ""
          ) {
            cliente.append(key, cliente_2[key]);
          }
        }

        if (Object.keys(this.endereco).length !== 0) {
          let endereco = {};
          endereco.rua = this.endereco.rua;
          endereco.numero = this.endereco.numero;
          endereco.cidade_nome = this.endereco.cidade_nome;
          endereco.estado = this.endereco.estado;
          endereco.bairro = this.endereco.bairro;
          endereco.pais = this.endereco.pais;
          cliente_2.endereco = endereco;

          cliente.append("endereco", JSON.stringify(cliente_2.endereco));
        }

        postClienteCombobox(cliente)
          .then((response) => {
            this.$emit("update:dialogCliente", false);
            this.$emit("fetch-clientes", response.data.result);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.status === 406) {
              this.$toast.error(this.$tc("global.jaExisteCliente"));
            } else {
              this.$toast.error(this.$tc("global.erroCliente"));
            }
            console.log(error.response);
          });
      }
    },

    updateCliente() {
      if (
        JSON.stringify(this.cliente) === JSON.stringify(this.cliente_original)
      ) {
        this.$toast.error("¡Haz un cambio antes de guardar!");
        return;
      }
      if (this.$refs.form.validate()) {
        if (!this.cliente.doc_ci && !this.cliente.doc_ruc) {
          this.$toast.error("por favor inserte algún documento");
          this.loading = false;
          return;
        }

        this.loading = true;

        const cliente = new FormData();

        let cliente_2 = {};
        cliente_2.doc_ci = this.cliente.doc_ci;
        cliente_2.doc_ruc = this.cliente.doc_ruc;
        cliente_2.nome = this.cliente.nome;
        cliente_2.email = this.cliente.email;
        cliente_2.endereco = this.cliente.endereco;
        cliente_2.celular = this.cliente.celular;
        cliente_2.telefone = this.cliente.telefone;
        cliente_2.localizacao = this.cliente.localizacao;
        cliente_2.lista_preco_id = this.cliente.lista_preco_id;
        cliente_2.moeda_id = this.cliente.moeda_id;
        cliente_2.data_nascimento = this.cliente.data_nascimento;
        cliente_2.genero = this.cliente.genero;
        cliente_2.agente_retentor = this.cliente.agente_retentor;
        cliente_2.local_trabalho = this.cliente.local_trabalho;
        cliente_2.pessoa_juridica = this.cliente.pessoa_juridica;
        cliente_2.data_limite_credito = this.cliente.data_limite_credito;
        cliente_2.estado_civil = this.cliente.estado_civil;
        cliente_2.credito = this.$ci.parse(
          this.valorCreditoCliente,
          this.vCurrencyOptions
        );

        for (let key in cliente_2) {
          if (
            cliente_2[key] !== null &&
            cliente_2[key] !== undefined &&
            cliente_2[key] !== ""
          ) {
            cliente.append(key, cliente_2[key]);
          }
        }

        putClienteCombobox(this.cliente.id, cliente)
          .then(() => {})
          .finally(() => {
            this.loading = false;
          })
          .catch((error) => {
            this.$toast.error(this.$tc(error.response.data.message));
          });
      }
    },
  },

  async mounted() {
    if (this.cliente_id) {
      this.getClienteCombobox();
    }
    await this.getListas();
  },
};
</script>

<style></style>
